import { BROKER_LANDING_PAGE, BROKER_LANDING_PAGE_SELLER } from "../pages/broker/constants/routes";
import { TITLE_REGEX, WHITE_SPACES } from "./regex";

export const BASE = process.env.PUBLIC_URL;
export const HOME = `${BASE}/`;

// BUYER
export const BUYER_GUIDE = `${BASE}/my-sunhub/buying-overview/sunhub-guide`;
export const BUYER_BUYING_HISTORY = `${BASE}/my-sunhub/buying-overview/purchase-history`;
export const TRADER_GUIDE = `${BASE}/my-sunhub/buying-overview/trader-guide`;
export const BIDS_OFFERS = `${BASE}/my-sunhub/buying-overview/offer-bids`;
export const DEALS_HISTORY = `${BASE}/my-sunhub/buying-overview/purchases`;
export const REVIEWS_FEEDBACK = `${BASE}/my-sunhub/buying-overview/reviews-feedback`;

// SELLER
export const SELLER_OVERVIEW = `${BASE}/my-sunhub/seller-overview`;
export const SELLER_SNAPSHOT = `${BASE}/my-sunhub/seller-overview/sales-snapshot`;
export const SELLER_SELL_AN_ITEM = `${BASE}/my-sunhub/seller-overview/sell-an-item`;
export const SELLER_MY_LISTING = `${BASE}/my-sunhub/seller-overview/my-listing`;
export const SELLER_BUYING_HISTORY = `${BASE}/my-sunhub/seller-overview/sold-items`;
export const BROKER_DEAL = `${BASE}/trader/create-deal`;
export const TRADER_DEALS = `${BASE}/trader/trader-deals`;
export const INVERTER_DEAL = `${BASE}/trader/create-deal-inverter`;
export const BATTERIES_DEAL = `${BASE}/trader/create-deal-battery`;
export const OTHER_DEAL = `${BASE}/trader/create-other-component-deal`;

//chat
export const BROKER_CHAT_BUYER = `${BASE}/trader/chat/buyer`;
export const BROKER_CHAT_SELLER = `${BASE}/trader/chat/seller`;
// export const BROKER_DEAL_EDIT = (dealId)=> `${BASE}/broker/edit-deal/:${dealId}`;
export const BROKER_DEAL_EDIT = `${BASE}/trader/edit-deal`;
export const BROKER_EDIT_INVENTORY = `${BASE}/trader/edit-inventory`;
export const BROKER_DEAL_LISTING_TABLE = `${BASE}/trader/deals-listing`;
export const BROKER_SOLD_DEAL_LISTING_TABLE = `${BASE}/trader/sold-listing`;
export const BROKER_DEAL_ACTIVE_LISTING_TABLE = `${BASE}/trader/active-listing`;
export const BROKER_DEAL_OFFER_OR_BID_LISTING_TABLE = `${BASE}/trader/offer-or-bid`;
export const SELLER_PAYOUT_DETAILS = `${BASE}/my-sunhub/seller-overview/payout-details`;
export const SELLER_RESELLERS = `${BASE}/my-sunhub/seller-overview/resellers`;
export const SELLER_STRPE_ONBOARDING = `${BASE}/my-sunhub/seller-overview/stripe-onboarding`;
export const SELLER_OVERVIEW_EDIT_PRODUCT = `${BASE}/my-sunhub/seller-overview/product/1448`;
export const SELl_ON_SUNHUB = `${BASE}/register-business/sell-on-sunhub`;
export const BUSINESS_PROFILE = `${BASE}/my-sunhub/business-profile`;
export const USER_SETTING = `${BASE}/my-sunhub/user-setting`;
export const SHOP_WISHLIST = `${BASE}/shop/wishlist`;
export const BUYING_OVERVIEW = `${BASE}/my-sunhub/buying-overview`; // public view

// PRODUCT
export const PRODUCT_DETAIL = `${BASE}/product/default/:id`;
export const PRODUCT_DETAIL_PAGE = (name, id) =>
	`${HOME}product/${id}/${name && name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()}`;

// seller product detial view
export const SELLER_PRODUCT_DETAIL = `${BASE}/my-sunhub/product/detail/:id`;
/*
	ROLES:
	~~~~~~
	1 - ADMIN
	2 - SELLER
		i  - seller_setup: true
		ii - seller_setup: false
	3 - BUYER
	4 - CUSTOMER 
*/
//shop routes
export const LISTING_PAGE = `${BASE}/shop/find`;
export const EXPLOR_VIDEOS = `${BASE}/explore-videos`;
export const VIDEOS_DETAILS = `${BASE}/explore-video/detail`;
export const LISTING_PAGE_Home = `${BASE}/shop/product`;

//seller store
export const STORE_FRONT = `${BASE}/storefront/:store_name/:seller_id`;
export const STORE_FRONT_PAGE = (seller_id, store_name) =>
	STORE_FRONT.replace(":seller_id", seller_id).replace(
		":store_name",
		store_name && store_name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()
	);

//seller MarketPlace
export const SELLER_MARKETPLACE = `${BASE}/:store_name/seller-marketplace/:seller_id/find`;
export const SELLER_MARKETPLACE_PAGE = (seller_id, store_name) =>
	SELLER_MARKETPLACE.replace(":seller_id", seller_id).replace(
		":store_name",
		store_name && store_name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()
	);

//custom order listing route
export const CO_LISTING_PAGE = `${BASE}/custom-order-listing`;
export const CUSTOM_ORDER_DETAIL_PAGE = (name, id) =>
	`${HOME}custom-order-detail/${id}/${
		name && name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()
	}`;

// sunhub videos detail
//generaters

export const VIDEO_DETAIL_URL = (name, id) =>
	`${HOME}explore-video/detail/${
		name && name.replace(TITLE_REGEX, " ").trim().replace(WHITE_SPACES, "-").toLowerCase()
	}-${id}`;

// Access Control List ;)
export const ACL = {
	ADMIN: [
		// everything allowed ;)
	],
	SELLER: [
		SELLER_SNAPSHOT,
		SELLER_SELL_AN_ITEM,
		SELLER_MY_LISTING,
		SELLER_PAYOUT_DETAILS,
		SELLER_BUYING_HISTORY,
		SELLER_RESELLERS,
		SELLER_STRPE_ONBOARDING,
		SELl_ON_SUNHUB,
		BUSINESS_PROFILE,
		SELLER_OVERVIEW_EDIT_PRODUCT,
		USER_SETTING,
		SHOP_WISHLIST,
		BROKER_DEAL,
		BROKER_DEAL_EDIT,
		BROKER_DEAL_LISTING_TABLE,
		BROKER_CHAT_SELLER,
		BROKER_DEAL_ACTIVE_LISTING_TABLE,
		BROKER_DEAL_OFFER_OR_BID_LISTING_TABLE,
		BROKER_SOLD_DEAL_LISTING_TABLE,
		BROKER_LANDING_PAGE_SELLER,
		TRADER_DEALS,
		INVERTER_DEAL,
		BATTERIES_DEAL,
		OTHER_DEAL,
		BROKER_EDIT_INVENTORY,
		// BROKER_LANDING_PAGE,
	],
	BUYER: [
		BROKER_CHAT_BUYER,
		BUYER_GUIDE,
		BUYER_BUYING_HISTORY,
		TRADER_GUIDE,
		BIDS_OFFERS,
		REVIEWS_FEEDBACK,
		DEALS_HISTORY,
		TRADER_DEALS,
		// BROKER_LANDING_PAGE_SELLER,
		BROKER_LANDING_PAGE,
		INVERTER_DEAL,
		BATTERIES_DEAL,
		OTHER_DEAL,
	],
	CUSTOMER: [
		SHOP_WISHLIST,
		USER_SETTING,
		BUYING_OVERVIEW,
		BROKER_CHAT_BUYER,
		BUYER_GUIDE,
		BUYER_BUYING_HISTORY,
		TRADER_GUIDE,
		BIDS_OFFERS,
		REVIEWS_FEEDBACK,
		DEALS_HISTORY,
		TRADER_DEALS,
		INVERTER_DEAL,
		BATTERIES_DEAL,
		OTHER_DEAL,
		// BROKER_LANDING_PAGE_SELLER,
		BROKER_LANDING_PAGE,
	],
};
//ask for login
export const AFL = [BROKER_CHAT_SELLER, BROKER_CHAT_BUYER];

// brand page
export const BRAND_PAGE = `${BASE}/brands`;
// store front listing route
export const STORE_FRONT_LISTING = `${BASE}/storefront-listing`;
