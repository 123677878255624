import React, { memo, useEffect } from "react";
import { Helmet } from "react-helmet";
import { log } from "../../../utils";

const SEO = memo(({ title, keywords, description, canonicalUrl, productSchema, noindex, bodyClassName }) => {
	useEffect(() => {
		if (bodyClassName) {
			log("Body Class", bodyClassName);
			// Add a class to the <body> element
			document.body.classList.add(bodyClassName);
		}
		return () => {
			if (bodyClassName) {
				// Remove the class when the component unmounts
				document.body.classList.remove(bodyClassName);
			}
		};
	}, [bodyClassName]);

	return (
		<Helmet>
			{title && <title> {title} </title>}
			{title && (
				<meta
					name="title"
					content={title}
				/>
			)}
			{keywords && (
				<meta
					name="keywords"
					content={keywords}
				/>
			)}
			{description && (
				<meta
					name="description"
					content={description}
					data-react-helmet="true"
				/>
			)}
			{canonicalUrl && (
				<link
					rel="canonical"
					href={canonicalUrl}
				/>
			)}
			{/* {image && <meta name="twitter:image" content={`${image}`} />} */}
			{title && (
				<meta
					name="title"
					property="og:title"
					content={title}
				/>
			)}
			{description && (
				<meta
					name="og:description"
					property="og:description"
					content={description}
				/>
			)}
			{/* {image && <meta name="image" property="og:image" content={`${image}`} />} */}
			{/* {title && <meta name="twitter:title" content={title} />} */}
			{/* {description && <meta name="twitter:description" content={description} />} */}
			{productSchema && <script type="application/ld+json">{`${JSON.stringify(productSchema)}`}</script>}
			{noindex && (
				<meta
					name="robots"
					content="noindex"></meta>
			)}
		</Helmet>
	);
});

SEO.displayName = SEO;
export default SEO;
